<template>
  <a-card :bordered='false'>
    <div class="s_flex_center p-b-40">
      <div class="s_flex_center">
        <p>选择品牌商：</p>
        <a-input readonly class='m-r-15' :style="{width:'380px'}" v-model:value="brand_name" placeholder="请选择品牌商" />
        <CloseCircleOutlined class="CloseCircleOutlined s_shou" @click="clearVal" />
        <a-button class="" type="primary" @click='showBrand = true;'>选择</a-button>
      </div>
      <div class="s_flex_center m-l-15">
        <p>标签：</p>
        <a-input class='m-r-15' :style="{width:'380px'}" v-model:value="code" placeholder="请输入标签" />
      </div>
      <a-button type="primary" v-has:codeInformationService='onSubmit'>查询</a-button>
    </div>
    <p class="p-b-10 s_text_c" style="height:200px;margin-top:100px" v-if='errorMsg'>{{errorMsg}}</p>
    <a-descriptions bordered :column='2' class="m-t-20" v-if="showData">
      <a-descriptions-item label="流水号">{{codedata.code_sn || '-'}}</a-descriptions-item>
      <a-descriptions-item label="标签类型">{{codedata.current_code_type || '-'}}</a-descriptions-item>
      <a-descriptions-item label="批次号">{{codedata.batch_sn || '-'}}</a-descriptions-item>
      <a-descriptions-item label="品牌名称" :span='1'>{{codedata.brand_name}}</a-descriptions-item>
      <a-descriptions-item label="批次备注" >{{codedata.batch_remarks || '-'}}</a-descriptions-item>
      <a-descriptions-item label="防伪码">{{codedata.security_code || '-'}}</a-descriptions-item>

    </a-descriptions>
  </a-card>
  <a-modal :keyboard="false" title="选择经品牌商" centered v-model:visible="showBrand" :width='1200' :footer="null" destroyOnClose>
    <selectBrand v-on:select="brandSelect" :checkout="false"  :selectList="[{id:brand_id}]" />
  </a-modal>
</template>
<script>
import { ref, reactive, toRefs, onMounted, inject } from "vue";
import { message } from "ant-design-vue";
import { $iscode } from "@/utils/app";

import { codeSearch } from "@/api/codeManage";
import { DownOutlined, UpOutlined } from "@ant-design/icons-vue";
import store from "@/store";
export default {
  components: {},
  setup() {
    const state = reactive({
      brand_id:"",
      brand_name: "",
      brand_no: "",
      code: "",
      errorMsg: "", //码错误提示语
      codedata: {}, //码信息
      showData: false, //是否显示查询结果
      info: store.state.user.info,
      showBrand: false,
    });

    const _lodash = inject("_lodash");
    const onSubmit = async () => {
      if (!state.code) {
        return message.error("请输入标签!");
      }else if(!state.brand_no){
        return message.error("请选择品牌商!");
      } 
      else {
        state.errorMsg = "";
        const hide = message.loading("正在加载中...", 0);
        let res = await codeSearch({ code: state.code.replace(/\s*/g, ""),brand_id:state.brand_no })
          .then((res) => res.data)
          .catch((error) => error);
        if ($iscode(res)) {
          console.log(res);
          state.codedata = res.data;
          state.showData = true;
        } else {
          message.error(res.msg);
          state.showData = false;
          state.errorMsg = res.msg;
        }
        setTimeout(hide);
      }
    };
    const brandSelect = (e) => {
      state.brand_no = e.brand_no;
      state.brand_id = e.id;
      state.brand_name = e.brand_name;
      state.showBrand = false;
    };
    const route = inject("$route");
    onMounted(() => {});
    return {
      ...toRefs(state),
      onSubmit,
      brandSelect,
    };
  },
};
</script>
<style lang="less" scoped>
.s_ptb16lr24 {
  padding: 14px 24px;
}

.list_bl {
  border-left: 1px solid #f0f0f0;
}

.list_bb {
  border-bottom: 1px solid #f0f0f0;
}

.list_bt {
  border-top: 1px solid #f0f0f0;
}

.red {
  color: #f04635;
}

.tree_box p:last-child {
  border-bottom: 0;
}

.ms_box .ms_box_item:last-child {
  border-bottom: 0;
}
/deep/.richContent img {
  width: 100%;
}
</style> 